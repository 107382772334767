<template>
  <div>
    <el-form style="width: 100%">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入学号">
            <el-input placeholder="请输入学号" v-model="searchData.num" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入学生姓名">
            <el-input placeholder="请输入学生姓名" v-model="searchData.name" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入身份证号">
            <el-input
              placeholder="请输入身份证号"
              v-model="searchData.id_card"
            />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入院系">
            <el-input
              placeholder="请输入院系"
              v-model="searchData.collegeName"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请输入专业">
            <el-input placeholder="请输入专业" v-model="searchData.domain" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="请输入年级">
            <el-input placeholder="请输入年级" v-model="searchData.grade" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入班级">
            <el-input placeholder="请输入班级" v-model="searchData.className" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="请输入老师姓名">
            <el-input
              placeholder="请输入老师姓名"
              v-model="searchData.teacher"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="请您选择层次">
            <el-select v-model="searchData.length" placeholder="请选择层次">
              <el-option
                v-for="item in hierarchicalData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button type="primary" @click="onSearch"> 搜索 </el-button>
            <el-button type="primary" @click="templateDownload">
              模板下载
            </el-button>
            <el-button type="primary" @click="excelImport">导入</el-button>
            <el-button
              v-permission="['financeAddFees']"
              type="primary"
              @click="contrastResults"
              >异常数据</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 表格 -->
    <el-table
      :data="oldStudent"
      border
      style="width: 100%"
      :header-cell-style="{ background: '#f2f2f2' }"
    >
      <el-table-column prop="num" label="学号" width="180" />
      <el-table-column prop="name" label="姓名" />
      <el-table-column
        prop="id_card"
        show-overflow-tooltip
        label="身份证号码"
        width="180"
      />
      <el-table-column prop="collegeName" label="院系" show-overflow-tooltip />
      <el-table-column prop="domain" label="专业" show-overflow-tooltip />
      <el-table-column prop="level_name" label="层次" show-overflow-tooltip />
      <el-table-column prop="grade" label="年级" />
      <el-table-column prop="year" label="入学年份" />
      <el-table-column prop="className" label="班级" width="120" />
      <el-table-column prop="teacher" label="老师姓名" />
      <el-table-column prop="remark" label="备注" />
      <el-table-column label="操作" align="center" width="180" fixed="right">
        <template #default="{ row }">
          <el-button type="primary" size="small" @click="onEditBtn(row)"
            >修改</el-button
          >
          <el-popconfirm
            title="是否删除该专业?"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="deleteBtn(row.id)"
          >
            <template #reference>
              <el-button type="danger" size="small"> 删除 </el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->

    <el-row style="margin-top: 20px">
      <el-col>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="searchData.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchData.total"
        >
        </el-pagination>
      </el-col>
    </el-row>

    <student-edit
      v-model="oldStudentShow"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></student-edit>
    <candidate-strast
      v-model="contrastVisible"
      :studentID="studentID"
      :onListOldStudent="onListOldStudent"
    ></candidate-strast>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'

import { listOldStudentAPI, deleteOldStudentAPI } from '@/api/candidate'
import { listOfHierarchicalListingAPI } from '@/api/specialized'
import { ElMessage } from 'element-plus'
import studentEdit from './components/student-edit.vue'
import candidateStrast from './components/candidateStrast.vue'

// 搜索表单
const searchData = ref({
  page: 1, // 当前页
  total: 0, // 总数
  limit: 10, //  每页几条数据
  num: '', //  学号
  name: '', //  学生姓名
  id_card: '', // 身份证
  collegeName: '', // 院系
  domain: '', //  专业
  grade: '', //  年级
  className: '', //  班级
  teacher: '' // 老师
})
// 层次下拉
const hierarchicalData = ref([])
const onLayerPullDown = async () => {
  const data = await listOfHierarchicalListingAPI()
  hierarchicalData.value = data
}
onLayerPullDown()
// 搜索
const onSearch = () => {
  onListOldStudent()
}

// 模板下载
const templateDownload = () => {
  window.location.href =
    'https://lnjsxy.college.yslts.com/excel/%E8%80%81%E7%94%9F%E4%BF%A1%E6%81%AF%E5%8F%8A%E7%BC%B4%E8%B4%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
}

/**
 *  表格 S
 */
const oldStudent = ref([])
const onListOldStudent = async () => {
  const { data, total } = await listOldStudentAPI(searchData.value)
  oldStudent.value = data
  searchData.value.total = total
}
onListOldStudent()

// 修改
const studentID = ref({})
const onEditBtn = ({ id }) => {
  studentID.value = id
  oldStudentShow.value = true
}

// 删除
const deleteBtn = async (id) => {
  await deleteOldStudentAPI({ id })
  ElMessage.success('老生信息删除成功 。')
  onListOldStudent()
}

// Excel 导入
const router = useRouter()
const excelImport = () => {
  router.push('/candidate/import')
}

/**
 *  表格 E
 */

/**
 *  分页 S
 */
// 每页获取多少数据
const handleSizeChange = (val) => {
  searchData.value.limit = val
  onListOldStudent()
}
const contrastVisible = ref(false)
const cont = ref({})
const contrastResults = (row) => {
  contrastVisible.value = true
  cont.value = row
}

// 页码值
const handleCurrentChange = (val) => {
  searchData.value.page = val
  onListOldStudent()
}

/**
 *  分页 E
 */

/** 修改 S */
const oldStudentShow = ref(false)
/** 修改 E */
</script>

<style lang="scss" scoped></style>
