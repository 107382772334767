<template>
  <el-dialog v-model="specializedShow" title="修改老生信息" width="50%">
    <el-form
      label-width="100px"
      :model="studentForm"
      :rules="studentFormRules"
      ref="studentdRef"
    >
      <el-form-item label="学号" prop="num">
        <el-input v-model="studentForm.num" disabled />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="studentForm.name" />
      </el-form-item>
      <el-form-item label="身份证号" prop="id_card">
        <el-input v-model="studentForm.id_card" disabled />
      </el-form-item>
      <el-form-item label="院系" prop="collegeName">
        <el-input v-model="studentForm.collegeName" />
      </el-form-item>
      <el-form-item label="专业" prop="domain">
        <el-input v-model="studentForm.domain" />
      </el-form-item>
      <el-form-item label="年级" prop="grade">
        <el-input v-model="studentForm.grade" />
      </el-form-item>
      <el-form-item label="班级" prop="className">
        <el-input v-model="studentForm.className" />
      </el-form-item>
      <el-form-item label="老师姓名" prop="teacher">
        <el-input v-model="studentForm.teacher" />
      </el-form-item>
      <el-form-item label="入学年份" prop="year">
        <el-input v-model="studentForm.year" />
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="studentForm.remark" type="textarea" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeBtn">取消 </el-button>
        <el-button type="primary" @click="onOkBtn">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import { editStudentAPI, studentDetailAPI } from '@/api/candidate'
import { ElMessage } from 'element-plus'
/**
 *  props S
 */
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },

  studentID: {
    type: Number
  },

  onListOldStudent: {
    type: Function
  }
})

defineEmits(['update:modelValue']) // 注册 v-model 事件
const specializedShow = useVModel(props)

watch(
  () => props.studentID,
  (val) => {
    onStudentDetail(val)
  },
  {
    deep: true
  }
)

const onStudentDetail = async (id) => {
  const data = await studentDetailAPI({ id })
  studentForm.value = data
}

/** props E */

/** 表单 S */
// From 实例
const studentdRef = ref(null)
const studentForm = ref({
  id: '',
  num: '', //  学号
  name: '', //  学生姓名
  id_card: '', // 身份证
  collegeName: '', // 院系
  domain: '', //  专业
  grade: '', //  年级
  className: '', // 班级
  teacher: '', // 老师
  remark: '' // 备注
})

// 验证规则
const studentFormRules = ref({
  num: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入学号'
    }
  ],
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入学生姓名'
    }
  ],
  id_card: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入身份证号'
    }
  ],
  collegeName: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入院系'
    }
  ],
  domain: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入专业'
    }
  ],
  grade: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入年级'
    }
  ],
  className: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入班级'
    }
  ],
  teacher: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入老师姓名'
    }
  ],
  remark: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入备注'
    }
  ]
})

/** 表单 E */

const closeBtn = () => {
  specializedShow.value = false
}

const onOkBtn = async () => {
  studentdRef.value.validate(async (valid) => {
    if (!valid) return
    await editStudentAPI(studentForm.value)
    ElMessage.success('修改老生信息成功')
    props.onListOldStudent()
    specializedShow.value = false
  })
}
</script>

<style></style>
